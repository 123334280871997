
.swiper-slide[data-v-691d61e2] {
  width: auto!important;
  background-color: #eee;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1;
  padding: .4rem 0;
}
@media (min-width: 768px) {
.swiper-slide[data-v-691d61e2] {
    font-size: 1.6rem;
    line-height: 2.4rem
}
}
.normal .swiper-slide[data-v-691d61e2]:hover {
  background-color: #000;
  color: #fff;
}
